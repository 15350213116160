(function() {
  let size = 0;

  function calcContentSize() {
    const header = document.querySelector('#m_header');
    const tickerWrap = document.querySelector('.ticker-wrap');
    const headerHeight = header && header.offsetHeight || 70;
    const tickerWrapHeight = tickerWrap && tickerWrap.offsetHeight || 0;
    const newSize = headerHeight + tickerWrapHeight;
    if (size !== newSize) {
      size = newSize;
      document.body.style.setProperty('--camp-contentHeight', 'calc(100vh - ' + (headerHeight + tickerWrapHeight + 16 + "px)"));
    }
  }

  calcContentSize();
  setInterval(() => {
    calcContentSize();
  }, 500);
})()

